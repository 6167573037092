<template>
  <b-row>
    <b-col cols="12">
    <!--<b-button @click="generatePDF">Download PDF</b-button>-->
      <div id="invoice-data" class="kt-portlet px-0" v-if="invoice">
        <div class="kt-portlet__body kt-portlet__body--fit">
          <div class="kt-invoice-2">

            <div class="kt-invoice__head kt-ribbon kt-ribbon--">
              <div class="kt-invoice__container">
                <div class="kt-invoice__brand">
                  <div class="col-md-8">
                    <h2 class="mb-0">
                      <span id="title">{{ invoice.document_type_name}} </span>
                      <span id="number">{{ invoice.document_seira_name }} #{{ invoice.document_number }}</span>
                    </h2>
                    <div>
                      <span>Ημερομηνία: {{ formattedDate(invoice.document_date) }}</span>
                    </div>

                    <div class="mt-4"><span class="kt-font-bold">Συναλλασσόμενος</span></div>
                    <div>
                      <span class="bold">{{ invoice.document_operator_name }} </span>
                    </div>
                    <div>
                      <span class="d-none"></span>
                    </div>
                    <div>
                      <span>{{ invoice.document_operator_address }}, {{ invoice.document_operator_city }}, {{ invoice.document_operator_postcode }}</span>
                    </div>
                    <div>
                      <span>{{ invoice.document_operator_phone }}</span>
                      <span v-if="invoice.document_operator_mobile"> , {{ invoice.document_operator_mobile }}</span> ,<span> {{ invoice.document_operator_email }}</span>
                    </div>
                    <div>
                      <span>Α.Φ.Μ.:{{ invoice.document_operator_vat_number }}</span>
                    </div>
                    <div>
                      <span></span>
                    </div>
                    <hr>
                    <strong><i>Παρελήφθησαν από τον/την {{ invoice.document_operator_name }}  τα κάτωθι αναφερόμενα.</i></strong>
                  </div>

                  <div class="kt-invoice__logo col-md-4">
                    <img src="https://signs.vergosauctions.com/img/vergos-new-logo-2022.png" :alt="companyDetails.company_name">
                    <span class="kt-invoice__desc">
                                            <span class="company-name">{{companyDetails.company_name}}</span>
                                            <span class="spacer"></span>
                                            <div>{{companyDetails.company_activity}}</div>
                                            <div>Α.Φ.Μ.:  {{companyDetails.company_vat_number}} - Δ.Ο.Υ.: Δ ́ ΑΘΗΝΩΝ</div>
                                            <div>{{companyDetails.company_address}}, {{companyDetails.company_city}}, {{companyDetails.company_postcode}}</div>
                                            <span class="clearfix"></span>
                                            <div>{{companyDetails.company_email}} | {{companyDetails.company_url}}</div>
                                        </span>
                  </div>
                </div>

              </div>
            </div>
            <div class="kt-invoice__body" v-if="invoice.invoice_items && invoice.invoice_items.length>0">
              <div class="kt-invoice__container">
                <div class="table-responsive">
                  <div class="kt-invoice__body">
                    <div class="kt-invoice__container" style="padding:0 15px">
                      <div class="table-responsive">
                        <table class="table table invoices_more_table">
                          <thead>
                          <tr>
                            <th>A/A</th>
                            <th>ΠΕΡΙΓΡΑΦΗ</th>
                            <th align="right">ΕΚΤΙΜΗΣΗ (€)</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(invoice_item, index) in invoice.invoice_items">
                            <td>{{ index+1 }} </td>
                            <td>{{ invoice_item.item_title }}</td>
                            <td align="right">{{ invoice_item.item_price_range}}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="kt-invoice__footer">
              <div class="kt-invoice__container">
                <div class="col-md-6"></div>
                <div class="col-md-6" style="padding-right: 0px !important;">
                  <table class="table table_invoice_ammounts">
                    <tbody>
                    <tr class="d-none">
                      <th>Ποσότητα</th>
                      <td>26</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="kt-separator kt-separator--border-dashed kt-separator--portlet-fit kt-separator--space-lg"></div>
              <div style="padding: 0 30px;">
                <strong>Παρατηρήσεις</strong>
                <div v-if="invoice.document_comments" v-html="invoice.document_comments"></div>
                <div ><hr>
                  <div v-if="!onlyForStock">
                    ** Φ.Π.Α. 24% επί της προμηθείας και του δικαιώματος παρακολούθησης.<br>
                    * Με βάση το Νόμο 2121 περί Πνευματικής Ιδιοκτησίας, άρθρα 5, 29 και 68, το ποσό αυτό διαμορφώνεται
                    σε ποσοστό 5% για το τμήμα της τιμής κατακύρωσης έως € 50.000, σε ποσοστό 3% για το τμήμα της τιμής
                    κατακύρωσης από € 50.000,01 έως € 200.000 και σε ποσοστό 1% για το τμήμα της τιμής κατακύρωσης από € 200.000,01 έως € 350.000.
                  </div>
                  <div v-else-if="warehousePosNeg==1">
                    <div v-if="invoice.document_type_name != 'Δελτίο Παραλαβής (από εξωτερικό συνεργάτη)'">
                      <b>Όροι:</b><br><br>
                      <ol>
                        <li>Οι αναγραφόμενες τιμές εκτίμησης είναι ενδεικτικές και ενδέχεται να μεταβληθούν κατόπιν εξέτασης των έργων και των αντικειμένων. Στην περίπτωση αυτή θα υπάρξει συνεννόηση με τον καταθέτη. Επίσης, και οι αναγραφόμενες περιγραφές ενδέχεται να μεταβληθούν κατόπιν εξέτασης των έργων και των αντικειμένων.</li>
                        <li>Όλα τα έργα και αντικείμενα ασφαλίζονται από τη στιγμή της παραλαβής τους στην έδρα μας, μέχρι και για 10 ημέρες μετά τη λήξη της δημοπρασίας ή της online πώλησης. Στη συνέχεια θα παραμένουν στους χώρους μας υπ’ ευθύνη του καταθέτη.</li>
                        <li>Ένα ή περισσότερα έργα και αντικείμενα είναι δυνατόν κατά την κρίση του Οίκου να μην περιληφθούν τελικά σε δημοπρασία ή online πώληση ή να συγχωνευτούν σε ομαδικούς λαχνούς.</li>
                        <li>Ποσοστό προμήθειας επί της τιμής κατακύρωσης στη δημοπρασία ή της τιμής πώλησης στην online πώληση:
                          <ul>
                            <li>για έργα ζωγραφικής και γλυπτικής 15% για ποσό έως € 3.000 και 10% για το τμήμα του ποσού από € 3.000 και άνω.</li>
                            <li>για όλα τα υπόλοιπα αντικείμενα 15%.</li>
                          </ul>
                          Από την τιμή κατακύρωσης ή πώλησης αφαιρείται μετά τη δημοπρασία ή την online πώληση το ποσοστό προμήθειας καθώς και ΦΠΑ 24% επί της προμήθειας.
                        </li>
                        <li>Η εξόφληση του καταθέτη γίνεται εντός 45 ημερών από τη λήξη της δημοπρασίας ή της online πώλησης, με την προϋπόθεση ότι τα έργα και αντικείμενα θα έχουν προηγουμένως εξοφληθεί από τους αγοραστές τους. Σε αντίθετη περίπτωση θα υπάρξει συνεννόηση με τον καταθέτη.</li>
                      </ol>
                      Υπογράφοντας παρακάτω δηλώνω ότι έχω λάβει γνώση και αποδέχομαι τους Όρους &amp; Προϋποθέσεις και την Πολιτική Απορρήτου.
                    </div>
                  </div>
                </div>
                <table width="100%" style="margin-bottom: 80px;">
                  <tbody><tr>
                    <td width="50%">
                      <b>Για τον Οίκο Δημοπρασιών ΒΕΡΓΟΣ</b><br><br>
                      <p v-if="invoice.user && invoice.user.first_name && invoice.user.last_name">{{ invoice.user.first_name }} {{ invoice.user.last_name }}</p>
                      <p>Υπογραφή</p>
                      <img style="width: 100%;max-width: 330px;" v-if="invoice.user && invoice.user.signature_image" src="https://signs.vergosauctions.com/img/vergos-pitza_720.png">
<!--                      <div v-if="hasSignatures">-->
<!--                        <img :src="'http://vergosapi.test/storage/'+invoice.signature_vergos">-->
<!--                      </div>-->
<!--                      <div v-else>-->
<!--                        <signature-pad id="vergos" ref="signatureComponentVergos"></signature-pad>-->
<!--                        <button class="mt-1 btn btn-primary btn-sm" @click="clearSignature('signatureComponentVergos')">Καθαρισμός</button>-->
<!--                      </div>-->
                    </td>
                    <td width="50%" align="right">
                      <b>Ο Συναλλασσόμενος</b><br><br>
                      <p>{{ invoice.document_operator_name }}</p>
                      <p>Υπογραφή</p>
                      <div v-if="hasSignature">
                        <img :src="signature_customer_image">
                      </div>
                      <div v-else>
                        <signature-pad id="customer" ref="signatureComponentCustomer"></signature-pad>
                        <button class="mt-1 btn btn-primary btn-sm" @click="clearSignature('signatureComponentCustomer')">Καθαρισμός</button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <hr>
                      <div v-if="hasSignature && invoice.signature_email_sent == 0" id="hide-for-pdf">
<!--                      <div v-if="hasSignature">-->
                        <div>
                          Θέλετε να στειλετε email στο {{invoice.document_operator_email}};
                          <button v-if="!changeDocumentOperatorEmail" class="btn btn-primary btn-sm" @click="changeDocumentOperatorEmail=true">Αλλαγή email</button>
                        </div>
                        <div v-if="changeDocumentOperatorEmail" class="mt-1">
                          <input class="form-control col-12 col-md-4 col-xl-3" v-model="invoice.document_operator_email"/>
                        </div>
                        <br>
                        <button v-if="changeDocumentOperatorEmail" class="mt-1 btn btn-success" @click="sendEmail">Αποθήκευση και Αποστολή email</button>
                        <button v-else class="mt-1 btn btn-success" @click="sendEmail" :disabled="sendingEmail">Αποστολή email</button>
                      </div>
                      <div v-if="hasSignature" class="text-right">
                        Ημερομηνία Υπογραφής: {{formattedDate(invoice.signature_datetime)}}
                      </div>
                      <div v-else>
                        <button class="mt-1 btn btn-success" @click="validate()">Αποθήκευση</button>
                      </div>
                      <div id="hide-for-pdf-1" class="alert alert-info col-3 pt-1 pr-1 pb-1 pb-1 pl-1 mt-auto mb-0" role="alert" v-if="invoice.signature_email_sent == 1">
                        To email έχει σταλεί.
                      </div>
                    </td>
                  </tr>
                  </tbody></table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router';
import { formatDateToMMDDYYYYHHMM } from '@/utils';
import SignaturePad from '/src/components/SignaturePad.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import axiosIns from "@/libs/axios";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    SignaturePad
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      invoiceId: null,
      changeDocumentOperatorEmail: false,
      sendingEmail: false
    }
  },
  computed: {
    signature_customer_image() {
      return this.$store.getters['data/getSignatureImage']
    },
    invoice() {
      return this.$store.getters['data/getInvoice']
    },
    companyDetails() {
      return this.$store.getters['data/getCompanyDetails']
    },
    onlyForStock() {
      return this.$store.getters['data/getOnlyForStock']
    },
    warehousePosNeg() {
      return this.$store.getters['data/getWarehousePosNeg']
    },
    hasSignatures() {
      if ((this.invoice.signature_vergos && this.invoice.signature_customer && this.invoice.signature_datetime)
          && (this.invoice.signature_vergos !== null && this.invoice.signature_customer !== null && this.invoice.signature_datetime !== null)
          && (this.invoice.signature_vergos !=='' && this.invoice.signature_customer !=='' && this.invoice.signature_datetime !=='')) {
        return true
      }
      return false
    },
    hasSignature() {
      if ((this.invoice.signature_customer && this.invoice.signature_datetime)
          && (this.invoice.signature_customer !== null && this.invoice.signature_datetime !== null)
          && (this.invoice.signature_customer !=='' && this.invoice.signature_datetime !=='')) {
        return true
      }
      return false
    },
  },
  created() {
    let vm = this
    this.invoiceId = this.$route.params.connection_id;
    this.$store.dispatch('data/fetchInvoice', this.invoiceId).then( function() {
      if (vm.invoice && vm.invoice.signature_customer_path) {
        vm.$store.dispatch('data/fetchInvoiceSignatureImage', vm.invoice.signature_customer_path)
      }
    })
  },
  methods: {
    formattedDate(date) {
      return formatDateToMMDDYYYYHHMM(date);
    },
    clearSignature(signatureRefName) {
      this.$refs[signatureRefName].clear();
    },
    validate() {
      // const signatureComponentVergos = this.$refs.signatureComponentVergos.saveSignature();
      const signatureComponentCustomer = this.$refs.signatureComponentCustomer.saveSignature();
      // if (!signatureComponentVergos && !signatureComponentCustomer) {
      if (!signatureComponentCustomer) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Προσοχή',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            // text: 'Οι υπογραφές δεν έχουν συμπληρωθεί',
            text: 'Η υπογραφή δεν έχει συμπληρωθεί',
          },
        })
      }
      else {
        // this.saveSignatures()
        this.saveSignature()
      }
    },
    async generatePDF() {
      const paddingTop = 20;
      const paddingBottom = 20;
      // Hide elements that should not be included in the PDF
      const elementsToHide = document.querySelectorAll('#hide-for-pdf');
      elementsToHide.forEach(el => el.style.display = 'none');
      const elementsToHide1 = document.querySelectorAll('#hide-for-pdf-1');
      elementsToHide1.forEach(el => el.style.display = 'none');

      // Get the content and generate the PDF
      const content = document.getElementById('invoice-data');
      const canvas = await html2canvas(content, { useCORS: true });
      const imgData = canvas.toDataURL('image/png');

      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: [297, 210],
        compressPdf: true
      });

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pageWidth;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      let position = paddingTop;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight - paddingTop - paddingBottom,'','FAST');
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight - paddingTop - paddingBottom,'','FAST');
        heightLeft -= pageHeight;
      }

      const currentUrl = window.location.href;
      const urlSegments = currentUrl.split('/');
      const guid = urlSegments[urlSegments.length - 1];

      // Save the PDF in storage
      const pdfBlob = pdf.output('blob');
      const formData = new FormData();
      formData.append('file', pdfBlob, 'invoice.pdf');
      formData.append('guid', guid);

      const response = await axiosIns.post('/invoices/save-pdf', formData)
      const responseData = response.data
      const pdfPath = responseData.path

      // Show elements again after generating the PDF
      elementsToHide.forEach(el => el.style.display = '');
      elementsToHide1.forEach(el => el.style.display = '');

      return pdfPath; // Return the path for further use
    },
    async sendEmail() {
      let vm = this;
      const appLoading = document.getElementById('loading-bg');
      if (appLoading) {
        appLoading.style.display = 'block';
      }
      if (vm.changeDocumentOperatorEmail == true) {
        vm.changeDocumentOperatorEmail = false;
      }

      // Generate the PDF and get the path
      const pdfPath = await this.generatePDF();
      if (!pdfPath) {
        vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Προσοχή',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: 'Failed to generate the PDF',
          },
        });
        return;
      }

      vm.sendingEmail = true;

      // Dispatch the action to send the email with the PDF attachment
      this.$store.dispatch('data/sendEmailDocument', {
        invoice: this.invoice,
        companyDetails: this.companyDetails,
        onlyForStock: this.onlyForStock,
        warehousePosNeg: this.warehousePosNeg,
        pdfPath: pdfPath // Include the PDF path
      }).then(response => {
        vm.sendingEmail = false;
        if (appLoading) {
          appLoading.style.display = 'none';
        }
        let variant = 'success';
        let title = 'Επιτυχία';

        if (!response.status) {
          variant = 'danger';
          title = 'Προσοχή';
        } else {
          vm.invoice.signature_email_sent = 1;
        }
        vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: title,
            icon: 'AlertCircleIcon',
            variant: variant,
            text: response.message,
          },
        });
      }).catch(error => {
        vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Προσοχή',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        });
      });
    },
    saveSignatures() {
      let vm = this
      const signatureComponentVergos = this.$refs.signatureComponentVergos.saveSignature();
      const signatureComponentCustomer = this.$refs.signatureComponentCustomer.saveSignature();
      let payload = {
        signatureComponentVergos: signatureComponentVergos,
        signatureComponentCustomer: signatureComponentCustomer,
        connection_id: this.invoiceId
      }
      this.$store.dispatch('data/saveSignatures', payload).then(response => {
        let variant = 'success'
        let title = 'Επιτυχία'

        if (!response.status) {
          variant = 'danger'
          title = 'Προσοχή'
        }
        vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: title,
            icon: 'AlertCircleIcon',
            variant: variant,
            text: response.message,
          },
        })
        // vm.sendEmail()
      })
      .catch(error => {
        vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Προσοχή',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        })
      })
    },
    saveSignature() {
      let vm = this
      const signatureComponentCustomer = this.$refs.signatureComponentCustomer.saveSignature();
      let payload = {
        signatureComponentVergos: null,
        signatureComponentCustomer: signatureComponentCustomer,
        connection_id: this.invoiceId
      }
      this.$store.dispatch('data/saveSignatures', payload).then(response => {
        let variant = 'success'
        let title = 'Επιτυχία'
        console.log('response', response)

        vm.$store.dispatch('data/setInvoiceSignatureImage', signatureComponentCustomer)
        if (!response.status) {
          variant = 'danger'
          title = 'Προσοχή'
        }
        vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: title,
            icon: 'AlertCircleIcon',
            variant: variant,
            text: response.message,
          },
        })

        // vm.sendEmail()
      })
      .catch(error => {
        vm.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Προσοχή',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        })
      })
    },
  },
}
</script>
<style>

.kt-portlet {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  background-color: #ffffff;
  margin-bottom: 20px;
  border-radius: 4px; }
.kt-page-content-white .kt-portlet {
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.1); }
.kt-portlet .kt-portlet__head {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  padding: 0 25px;
  border-bottom: 1px solid #ebedf2;
  min-height: 60px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }
.kt-portlet .kt-portlet__head.kt-portlet__head--right {
  justify-content: flex-end; }
.kt-portlet .kt-portlet__head.kt-portlet__head--noborder {
  border-bottom: 0; }
.kt-portlet .kt-portlet__head .kt-portlet__head-label {
  display: flex;
  align-items: center;
  align-content: flex-first; }
.kt-portlet .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  font-weight: 500;
  color: #48465b; }
.kt-portlet .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title small {
  font-weight: 300;
  padding-left: 0.5rem;
  font-size: 1rem;
  color: #74788d; }
.kt-portlet .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-icon {
  padding-right: 0.75rem;
  font-size: 1.3rem;
  color: #74788d; }
.kt-portlet .kt-portlet__head .kt-portlet__head-toolbar {
  display: flex;
  align-items: center;
  align-content: flex-end; }
.kt-portlet .kt-portlet__head .kt-portlet__head-toolbar .nav-pills,
.kt-portlet .kt-portlet__head .kt-portlet__head-toolbar .nav-tabs {
  margin: 0; }
.kt-portlet .kt-portlet__head .kt-portlet__head-toolbar .kt-portlet__head-wrapper {
  display: flex;
  align-items: center; }
.kt-portlet .kt-portlet__head .kt-portlet__head-progress {
  position: absolute;
  left: 0;
  right: 0; }
.kt-portlet.kt-portlet--head-sm .kt-portlet__head {
  min-height: 50px; }
.kt-portlet.kt-portlet--head-lg .kt-portlet__head {
  min-height: 80px; }
.kt-portlet.kt-portlet--head-xl .kt-portlet__head {
  min-height: 100px; }
.kt-portlet.kt-portlet--sticky .kt-portlet__head {
  height: 50px;
  min-height: 50px; }
.kt-portlet.kt-portlet--sticky .kt-portlet__head.kt-portlet__head--sm {
  height: 40px;
  min-height: 40px; }
.kt-portlet.kt-portlet--sticky .kt-portlet__head.kt-portlet__head--lg {
  height: 70px;
  min-height: 70px; }
.kt-portlet.kt-portlet--sticky .kt-portlet__head.kt-portlet__head--xl {
  height: 90px;
  min-height: 90px; }
.kt-portlet.kt-portlet--head-overlay .kt-portlet__head {
  position: relative;
  z-index: 1;
  border: 0;
  height: 60px; }
.kt-portlet.kt-portlet--head-overlay .kt-portlet__body {
  margin-top: -60px; }
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-sm .kt-portlet__head {
  height: 50px; }
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-sm .kt-portlet__body {
  margin-top: -50px; }
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-lg .kt-portlet__head {
  height: 80px; }
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-lg .kt-portlet__body {
  margin-top: -80px; }
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-xl .kt-portlet__head {
  height: 100px; }
.kt-portlet.kt-portlet--head-overlay.kt-portlet--head-xl .kt-portlet__body {
  margin-top: -100px; }
.kt-portlet .kt-portlet__body {
  display: flex;
  flex-direction: column;
  padding: 25px;
  border-radius: 4px; }
.kt-portlet .kt-portlet__body .kt-portlet__content {
  padding: 0;
  margin: 0; }
.kt-portlet .kt-portlet__body > .kt-datatable > .kt-datatable__table {
  border-radius: 4px; }
.kt-portlet .kt-portlet__foot {
  padding: 25px;
  border-top: 1px solid #ebedf2;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }
.kt-portlet .kt-portlet__foot .kt-portlet__foot-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }
.kt-portlet .kt-portlet__foot.kt-portlet__foot--sm {
  padding-top: 10px;
  padding-bottom: 10px; }
.kt-portlet .kt-portlet__foot.kt-portlet__foot--md {
  padding-top: 1rem;
  padding-bottom: 1rem; }
.kt-portlet .kt-portlet__foot.kt-portlet__foot--no-border {
  border-top: 0; }
.kt-portlet .kt-portlet__foot.kt-portlet__foot--top {
  border-top: 0;
  border-bottom: 1px solid #ebedf2; }
.kt-portlet .kt-portlet__foot.kt-portlet__foot--solid {
  background-color: #f7f8fa;
  border-top: 0; }
.kt-portlet .kt-portlet__foot.kt-portlet__foot--solid.kt-portlet__foot--top {
  border-bottom: 0; }
.kt-portlet.kt-portlet--bordered {
  box-shadow: none;
  border: 1px solid #ebedf2; }
.kt-portlet.kt-portlet--unelevate {
  box-shadow: none; }
.kt-portlet.kt-portlet--unround .kt-portlet__head {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
.kt-portlet.kt-portlet--unround .kt-portlet__body {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
.kt-portlet.kt-portlet--unround .kt-portlet__foot {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
.kt-portlet.kt-portlet--last {
  margin-bottom: 0; }
.kt-portlet .kt-portlet__body.kt-portlet__body--center {
  justify-content: center;
  align-items: center; }
.kt-portlet .kt-portlet__body.kt-portlet__body--center-x {
  justify-content: center; }
.kt-portlet .kt-portlet__body.kt-portlet__body--center-y {
  align-items: center; }
.kt-portlet .kt-portlet__body .kt-portlet__body--hor-fit {
  margin-left: -25px;
  margin-right: -25px; }
.kt-portlet .kt-portlet__body.kt-portlet__body--stick-bottom {
  position: relative;
  bottom: 0; }
.kt-portlet .kt-portlet__body.kt-portlet__body--fluid {
  height: 100%;
  flex-direction: row;
  flex-grow: 1;
  width: 100%; }
.kt-portlet .kt-portlet__body.kt-portlet__body--fill {
  flex-grow: 1 !important; }
.kt-portlet .kt-portlet__body.kt-portlet__body--unfill {
  flex-grow: 0 !important; }
.kt-portlet .kt-portlet__body.kt-portlet__body--fullheight {
  flex-grow: auto; }
.kt-portlet.kt-portlet--fit .kt-portlet__head {
  padding: 0; }
.kt-portlet.kt-portlet--fit .kt-portlet__body {
  padding: 0; }
.kt-portlet.kt-portlet--fit .kt-portlet__foot {
  padding: 0; }
.kt-portlet.kt-portlet--fit.kt-portlet--height-fluid-half {
  align-items: stretch; }
.kt-portlet.kt-portlet--fit.kt-portlet--height-fluid-half .kt-portlet__body {
  height: 100%;
  flex-direction: column;
  align-items: stretch; }
.kt-portlet.kt-portlet--contain {
  overflow: hidden; }
.kt-portlet .kt-portlet__head.kt-portlet__head--fit {
  padding: 0; }
.kt-portlet .kt-portlet__body.kt-portlet__body--fit {
  padding: 0; }
.kt-portlet .kt-portlet__body.kt-portlet__body--fit-top {
  padding-top: 0 !important; }
.kt-portlet .kt-portlet__body.kt-portlet__body--fit-bottom {
  padding-bottom: 0 !important; }
.kt-portlet .kt-portlet__body.kt-portlet__body--fit-x,
.kt-portlet .kt-portlet__body.kt-portlet__body--hor-fit {
  padding-left: 0;
  padding-right: 0; }
.kt-portlet .kt-portlet__body.kt-portlet__body--fit-y {
  padding-top: 0;
  padding-bottom: 0; }
.kt-portlet .kt-portlet__foot.kt-portlet__foot--fit {
  padding: 0; }
.kt-portlet.kt-portlet--space {
  padding-left: 25px;
  padding-right: 25px; }
.kt-portlet.kt-portlet--space .kt-portlet__head,
.kt-portlet.kt-portlet--space .kt-portlet__body,
.kt-portlet.kt-portlet--space .kt-portlet__foot {
  padding-left: 0;
  padding-right: 0; }
.kt-portlet.kt-portlet--head-noborder .kt-portlet__head {
  border-bottom: 0; }
.kt-portlet.kt-portlet--head-noborder .kt-portlet__body {
  padding-top: 12.5px; }
.kt-portlet.kt-portlet--tabs .kt-portlet__head .kt-portlet__head-toolbar {
  display: flex;
  align-items: stretch; }
.kt-portlet.kt-portlet--tabs-border-3x .kt-portlet__head {
  border-bottom: 3px solid #f7f8fa; }
.kt-portlet.kt-portlet--tabs-border-3x .kt-portlet__head .nav.nav-tabs {
  margin-bottom: -3px; }
.kt-portlet.kt-portlet--solid-brand {
  background: #27AAE1; }
.kt-portlet.kt-portlet--solid-brand .kt-portlet__head {
  color: #ffffff;
  border-bottom: 1px solid transparent; }
.kt-portlet.kt-portlet--solid-brand .kt-portlet__head .kt-portlet__head-title {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-brand .kt-portlet__head .kt-portlet__head-title small {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-brand .kt-portlet__head .kt-portlet__head-icon i {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-brand .kt-portlet__body {
  padding-top: 5px; }
.kt-portlet.kt-portlet--solid-brand .kt-portlet__wrapper,
.kt-portlet.kt-portlet--solid-brand .kt-portlet__body {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-brand .kt-portlet__foot {
  color: #ffffff;
  border-top: 1px solid transparent;
  background: #1957f1; }
.kt-portlet.kt-portlet--tabs-border-3x-brand .kt-portlet__head {
  border-bottom: 3px solid rgba(54, 108, 243, 0.1); }
.kt-portlet.kt-portlet--border-bottom-brand {
  border-bottom: 3px solid rgba(54, 108, 243, 0.2); }
.kt-portlet.kt-portlet--solid-light {
  background: #ffffff; }
.kt-portlet.kt-portlet--solid-light .kt-portlet__head {
  color: #48465b;
  border-bottom: 1px solid transparent; }
.kt-portlet.kt-portlet--solid-light .kt-portlet__head .kt-portlet__head-title {
  color: #48465b; }
.kt-portlet.kt-portlet--solid-light .kt-portlet__head .kt-portlet__head-title small {
  color: #1c1d2a; }
.kt-portlet.kt-portlet--solid-light .kt-portlet__head .kt-portlet__head-icon i {
  color: #1c1d2a; }
.kt-portlet.kt-portlet--solid-light .kt-portlet__body {
  padding-top: 5px; }
.kt-portlet.kt-portlet--solid-light .kt-portlet__wrapper,
.kt-portlet.kt-portlet--solid-light .kt-portlet__body {
  color: #48465b; }
.kt-portlet.kt-portlet--solid-light .kt-portlet__foot {
  color: #48465b;
  border-top: 1px solid transparent;
  background: #f0f0f0; }
.kt-portlet.kt-portlet--tabs-border-3x-light .kt-portlet__head {
  border-bottom: 3px solid rgba(255, 255, 255, 0.1); }
.kt-portlet.kt-portlet--border-bottom-light {
  border-bottom: 3px solid rgba(255, 255, 255, 0.2); }
.kt-portlet.kt-portlet--solid-dark {
  background: #48465b; }
.kt-portlet.kt-portlet--solid-dark .kt-portlet__head {
  color: #ffffff;
  border-bottom: 1px solid transparent; }
.kt-portlet.kt-portlet--solid-dark .kt-portlet__head .kt-portlet__head-title {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-dark .kt-portlet__head .kt-portlet__head-title small {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-dark .kt-portlet__head .kt-portlet__head-icon i {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-dark .kt-portlet__body {
  padding-top: 5px; }
.kt-portlet.kt-portlet--solid-dark .kt-portlet__wrapper,
.kt-portlet.kt-portlet--solid-dark .kt-portlet__body {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-dark .kt-portlet__foot {
  color: #ffffff;
  border-top: 1px solid transparent;
  background: #1c1d2a; }
.kt-portlet.kt-portlet--tabs-border-3x-dark .kt-portlet__head {
  border-bottom: 3px solid rgba(40, 42, 60, 0.1); }
.kt-portlet.kt-portlet--border-bottom-dark {
  border-bottom: 3px solid rgba(40, 42, 60, 0.2); }
.kt-portlet.kt-portlet--solid-primary {
  background: #5867dd; }
.kt-portlet.kt-portlet--solid-primary .kt-portlet__head {
  color: #ffffff;
  border-bottom: 1px solid transparent; }
.kt-portlet.kt-portlet--solid-primary .kt-portlet__head .kt-portlet__head-title {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-primary .kt-portlet__head .kt-portlet__head-title small {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-primary .kt-portlet__head .kt-portlet__head-icon i {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-primary .kt-portlet__body {
  padding-top: 5px; }
.kt-portlet.kt-portlet--solid-primary .kt-portlet__wrapper,
.kt-portlet.kt-portlet--solid-primary .kt-portlet__body {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-primary .kt-portlet__foot {
  color: #ffffff;
  border-top: 1px solid transparent;
  background: #3f50d8; }
.kt-portlet.kt-portlet--tabs-border-3x-primary .kt-portlet__head {
  border-bottom: 3px solid rgba(143, 83, 161, 0.1); }
.kt-portlet.kt-portlet--border-bottom-primary {
  border-bottom: 3px solid rgba(143, 83, 161, 0.2); }
.kt-portlet.kt-portlet--solid-success {
  background: #1dc9b7; }
.kt-portlet.kt-portlet--solid-success .kt-portlet__head {
  color: #ffffff;
  border-bottom: 1px solid transparent; }
.kt-portlet.kt-portlet--solid-success .kt-portlet__head .kt-portlet__head-title {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-success .kt-portlet__head .kt-portlet__head-title small {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-success .kt-portlet__head .kt-portlet__head-icon i {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-success .kt-portlet__body {
  padding-top: 5px; }
.kt-portlet.kt-portlet--solid-success .kt-portlet__wrapper,
.kt-portlet.kt-portlet--solid-success .kt-portlet__body {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-success .kt-portlet__foot {
  color: #ffffff;
  border-top: 1px solid transparent;
  background: #19ae9f; }
.kt-portlet.kt-portlet--tabs-border-3x-success .kt-portlet__head {
  border-bottom: 3px solid rgba(29, 201, 183, 0.1); }
.kt-portlet.kt-portlet--border-bottom-success {
  border-bottom: 3px solid rgba(29, 201, 183, 0.2); }
.kt-portlet.kt-portlet--solid-info {
  background: #5578eb; }
.kt-portlet.kt-portlet--solid-info .kt-portlet__head {
  color: #ffffff;
  border-bottom: 1px solid transparent; }
.kt-portlet.kt-portlet--solid-info .kt-portlet__head .kt-portlet__head-title {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-info .kt-portlet__head .kt-portlet__head-title small {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-info .kt-portlet__head .kt-portlet__head-icon i {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-info .kt-portlet__body {
  padding-top: 5px; }
.kt-portlet.kt-portlet--solid-info .kt-portlet__wrapper,
.kt-portlet.kt-portlet--solid-info .kt-portlet__body {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-info .kt-portlet__foot {
  color: #ffffff;
  border-top: 1px solid transparent;
  background: #3a62e8; }
.kt-portlet.kt-portlet--tabs-border-3x-info .kt-portlet__head {
  border-bottom: 3px solid rgba(85, 120, 235, 0.1); }
.kt-portlet.kt-portlet--border-bottom-info {
  border-bottom: 3px solid rgba(85, 120, 235, 0.2); }
.kt-portlet.kt-portlet--solid-warning {
  background: #ffb822; }
.kt-portlet.kt-portlet--solid-warning .kt-portlet__head {
  color: #111111;
  border-bottom: 1px solid transparent; }
.kt-portlet.kt-portlet--solid-warning .kt-portlet__head .kt-portlet__head-title {
  color: #111111; }
.kt-portlet.kt-portlet--solid-warning .kt-portlet__head .kt-portlet__head-title small {
  color: #020202; }
.kt-portlet.kt-portlet--solid-warning .kt-portlet__head .kt-portlet__head-icon i {
  color: #020202; }
.kt-portlet.kt-portlet--solid-warning .kt-portlet__body {
  padding-top: 5px; }
.kt-portlet.kt-portlet--solid-warning .kt-portlet__wrapper,
.kt-portlet.kt-portlet--solid-warning .kt-portlet__body {
  color: #111111; }
.kt-portlet.kt-portlet--solid-warning .kt-portlet__foot {
  color: #111111;
  border-top: 1px solid transparent;
  background: #ffae03; }
.kt-portlet.kt-portlet--tabs-border-3x-warning .kt-portlet__head {
  border-bottom: 3px solid rgba(255, 184, 34, 0.1); }
.kt-portlet.kt-portlet--border-bottom-warning {
  border-bottom: 3px solid rgba(255, 184, 34, 0.2); }
.kt-portlet.kt-portlet--solid-danger {
  background: #ab8f46; }
.kt-portlet.kt-portlet--solid-danger .kt-portlet__head {
  color: #ffffff;
  border-bottom: 1px solid transparent; }
.kt-portlet.kt-portlet--solid-danger .kt-portlet__head .kt-portlet__head-title {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-danger .kt-portlet__head .kt-portlet__head-title small {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-danger .kt-portlet__head .kt-portlet__head-icon i {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-danger .kt-portlet__body {
  padding-top: 5px; }
.kt-portlet.kt-portlet--solid-danger .kt-portlet__wrapper,
.kt-portlet.kt-portlet--solid-danger .kt-portlet__body {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-danger .kt-portlet__foot {
  color: #ffffff;
  border-top: 1px solid transparent;
  background: #fd1b66; }
.kt-portlet.kt-portlet--tabs-border-3x-danger .kt-portlet__head {
  border-bottom: 3px solid rgba(253, 57, 122, 0.1); }
.kt-portlet.kt-portlet--border-bottom-danger {
  border-bottom: 3px solid rgba(253, 57, 122, 0.2); }
.kt-portlet.kt-portlet--sortable .kt-portlet__head {
  cursor: move; }
.kt-portlet.kt-portlet--sortable-empty {
  visibility: hidden;
  height: 45px;
  min-height: 125px; }
.kt-portlet.ui-sortable-helper {
  border: 1px dashed #ebedf2; }
.kt-portlet .kt-portlet__head {
  transition: left 0.3s, right 0.3s, height 0.3s; }
.kt-portlet.kt-portlet--sticky .kt-portlet__head {
  transition: left 0.3s, right 0.3s, height 0.3s;
  position: fixed;
  box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.1);
  z-index: 101;
  background: #fff; }
.kt-portlet.kt-portlet--skin-solid .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title {
  color: #fff; }
.kt-portlet.kt-portlet--skin-solid .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title small {
  color: rgba(255, 255, 255, 0.8); }
.kt-portlet.kt-portlet--skin-solid .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-icon {
  color: rgba(255, 255, 255, 0.8); }
.kt-portlet.kt-portlet--skin-solid .kt-portlet__head:not(.kt-portlet__head--noborder) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
.kt-portlet.kt-portlet--skin-solid .kt-portlet__body {
  color: #fff; }
.kt-portlet .kt-portlet__space-x {
  padding-left: 25px !important;
  padding-right: 25px !important; }
.kt-portlet .kt-portlet__space-y {
  padding-top: 25px !important;
  padding-bottom: 25px !important; }
.kt-portlet.kt-portlet--collapsed > .kt-form,
.kt-portlet.kt-portlet--collapsed > .kt-portlet__body {
  display: none; }
.kt-portlet.kt-portlet--collapsed .kt-portlet__head .kt-portlet__head-toolbar .la-angle-down:before, .kt-portlet.kt-portlet--collapse .kt-portlet__head .kt-portlet__head-toolbar .la-angle-down:before {
  content: "\f113"; }
.kt-portlet.kt-portlet--collapsed .kt-portlet__head .kt-portlet__head-toolbar .la-plus:before, .kt-portlet.kt-portlet--collapse .kt-portlet__head .kt-portlet__head-toolbar .la-plus:before {
  content: "\f28e"; }
.kt-portlet .kt-portlet__head.kt-portlet__head--fit {
  padding: 0; }
.kt-portlet .kt-portlet__body.kt-portlet__body--fit {
  padding: 0; }
.kt-portlet .kt-portlet__body.kt-portlet__body--fit-top {
  padding-top: 0 !important; }
.kt-portlet .kt-portlet__body.kt-portlet__body--fit-bottom {
  padding-bottom: 0 !important; }
.kt-portlet .kt-portlet__body.kt-portlet__body--fit-x,
.kt-portlet .kt-portlet__body.kt-portlet__body--hor-fit {
  padding-left: 0;
  padding-right: 0; }
.kt-portlet .kt-portlet__body.kt-portlet__body--fit-y {
  padding-top: 0;
  padding-bottom: 0; }
.kt-portlet .kt-portlet__foot.kt-portlet__foot--fit {
  padding: 0; }
.kt-portlet.kt-portlet--space {
  padding-left: 25px;
  padding-right: 25px; }
.kt-portlet.kt-portlet--space .kt-portlet__head,
.kt-portlet.kt-portlet--space .kt-portlet__body,
.kt-portlet.kt-portlet--space .kt-portlet__foot {
  padding-left: 0;
  padding-right: 0; }
.kt-portlet.kt-portlet--head-noborder .kt-portlet__head {
  border-bottom: 0; }
.kt-portlet.kt-portlet--head-noborder .kt-portlet__body {
  padding-top: 12.5px; }
.kt-portlet.kt-portlet--tabs .kt-portlet__head .kt-portlet__head-toolbar {
  display: flex;
  align-items: stretch; }
.kt-portlet.kt-portlet--tabs-border-3x .kt-portlet__head {
  border-bottom: 3px solid #f7f8fa; }
.kt-portlet.kt-portlet--tabs-border-3x .kt-portlet__head .nav.nav-tabs {
  margin-bottom: -3px; }
.kt-portlet.kt-portlet--solid-brand {
  background: #27AAE1; }
.kt-portlet.kt-portlet--solid-brand .kt-portlet__head {
  color: #ffffff;
  border-bottom: 1px solid transparent; }
.kt-portlet.kt-portlet--solid-brand .kt-portlet__head .kt-portlet__head-title {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-brand .kt-portlet__head .kt-portlet__head-title small {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-brand .kt-portlet__head .kt-portlet__head-icon i {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-brand .kt-portlet__body {
  padding-top: 5px; }
.kt-portlet.kt-portlet--solid-brand .kt-portlet__wrapper,
.kt-portlet.kt-portlet--solid-brand .kt-portlet__body {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-brand .kt-portlet__foot {
  color: #ffffff;
  border-top: 1px solid transparent;
  background: #1957f1; }
.kt-portlet.kt-portlet--tabs-border-3x-brand .kt-portlet__head {
  border-bottom: 3px solid rgba(54, 108, 243, 0.1); }
.kt-portlet.kt-portlet--border-bottom-brand {
  border-bottom: 3px solid rgba(54, 108, 243, 0.2); }
.kt-portlet.kt-portlet--solid-light {
  background: #ffffff; }
.kt-portlet.kt-portlet--solid-light .kt-portlet__head {
  color: #48465b;
  border-bottom: 1px solid transparent; }
.kt-portlet.kt-portlet--solid-light .kt-portlet__head .kt-portlet__head-title {
  color: #48465b; }
.kt-portlet.kt-portlet--solid-light .kt-portlet__head .kt-portlet__head-title small {
  color: #1c1d2a; }
.kt-portlet.kt-portlet--solid-light .kt-portlet__head .kt-portlet__head-icon i {
  color: #1c1d2a; }
.kt-portlet.kt-portlet--solid-light .kt-portlet__body {
  padding-top: 5px; }
.kt-portlet.kt-portlet--solid-light .kt-portlet__wrapper,
.kt-portlet.kt-portlet--solid-light .kt-portlet__body {
  color: #48465b; }
.kt-portlet.kt-portlet--solid-light .kt-portlet__foot {
  color: #48465b;
  border-top: 1px solid transparent;
  background: #f0f0f0; }
.kt-portlet.kt-portlet--tabs-border-3x-light .kt-portlet__head {
  border-bottom: 3px solid rgba(255, 255, 255, 0.1); }
.kt-portlet.kt-portlet--border-bottom-light {
  border-bottom: 3px solid rgba(255, 255, 255, 0.2); }
.kt-portlet.kt-portlet--solid-dark {
  background: #48465b; }
.kt-portlet.kt-portlet--solid-dark .kt-portlet__head {
  color: #ffffff;
  border-bottom: 1px solid transparent; }
.kt-portlet.kt-portlet--solid-dark .kt-portlet__head .kt-portlet__head-title {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-dark .kt-portlet__head .kt-portlet__head-title small {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-dark .kt-portlet__head .kt-portlet__head-icon i {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-dark .kt-portlet__body {
  padding-top: 5px; }
.kt-portlet.kt-portlet--solid-dark .kt-portlet__wrapper,
.kt-portlet.kt-portlet--solid-dark .kt-portlet__body {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-dark .kt-portlet__foot {
  color: #ffffff;
  border-top: 1px solid transparent;
  background: #1c1d2a; }
.kt-portlet.kt-portlet--tabs-border-3x-dark .kt-portlet__head {
  border-bottom: 3px solid rgba(40, 42, 60, 0.1); }
.kt-portlet.kt-portlet--border-bottom-dark {
  border-bottom: 3px solid rgba(40, 42, 60, 0.2); }
.kt-portlet.kt-portlet--solid-primary {
  background: #5867dd; }
.kt-portlet.kt-portlet--solid-primary .kt-portlet__head {
  color: #ffffff;
  border-bottom: 1px solid transparent; }
.kt-portlet.kt-portlet--solid-primary .kt-portlet__head .kt-portlet__head-title {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-primary .kt-portlet__head .kt-portlet__head-title small {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-primary .kt-portlet__head .kt-portlet__head-icon i {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-primary .kt-portlet__body {
  padding-top: 5px; }
.kt-portlet.kt-portlet--solid-primary .kt-portlet__wrapper,
.kt-portlet.kt-portlet--solid-primary .kt-portlet__body {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-primary .kt-portlet__foot {
  color: #ffffff;
  border-top: 1px solid transparent;
  background: #3f50d8; }
.kt-portlet.kt-portlet--tabs-border-3x-primary .kt-portlet__head {
  border-bottom: 3px solid rgba(143, 83, 161, 0.1); }
.kt-portlet.kt-portlet--border-bottom-primary {
  border-bottom: 3px solid rgba(143, 83, 161, 0.2); }
.kt-portlet.kt-portlet--solid-success {
  background: #1dc9b7; }
.kt-portlet.kt-portlet--solid-success .kt-portlet__head {
  color: #ffffff;
  border-bottom: 1px solid transparent; }
.kt-portlet.kt-portlet--solid-success .kt-portlet__head .kt-portlet__head-title {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-success .kt-portlet__head .kt-portlet__head-title small {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-success .kt-portlet__head .kt-portlet__head-icon i {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-success .kt-portlet__body {
  padding-top: 5px; }
.kt-portlet.kt-portlet--solid-success .kt-portlet__wrapper,
.kt-portlet.kt-portlet--solid-success .kt-portlet__body {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-success .kt-portlet__foot {
  color: #ffffff;
  border-top: 1px solid transparent;
  background: #19ae9f; }
.kt-portlet.kt-portlet--tabs-border-3x-success .kt-portlet__head {
  border-bottom: 3px solid rgba(29, 201, 183, 0.1); }
.kt-portlet.kt-portlet--border-bottom-success {
  border-bottom: 3px solid rgba(29, 201, 183, 0.2); }
.kt-portlet.kt-portlet--solid-info {
  background: #5578eb; }
.kt-portlet.kt-portlet--solid-info .kt-portlet__head {
  color: #ffffff;
  border-bottom: 1px solid transparent; }
.kt-portlet.kt-portlet--solid-info .kt-portlet__head .kt-portlet__head-title {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-info .kt-portlet__head .kt-portlet__head-title small {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-info .kt-portlet__head .kt-portlet__head-icon i {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-info .kt-portlet__body {
  padding-top: 5px; }
.kt-portlet.kt-portlet--solid-info .kt-portlet__wrapper,
.kt-portlet.kt-portlet--solid-info .kt-portlet__body {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-info .kt-portlet__foot {
  color: #ffffff;
  border-top: 1px solid transparent;
  background: #3a62e8; }
.kt-portlet.kt-portlet--tabs-border-3x-info .kt-portlet__head {
  border-bottom: 3px solid rgba(85, 120, 235, 0.1); }
.kt-portlet.kt-portlet--border-bottom-info {
  border-bottom: 3px solid rgba(85, 120, 235, 0.2); }
.kt-portlet.kt-portlet--solid-warning {
  background: #ffb822; }
.kt-portlet.kt-portlet--solid-warning .kt-portlet__head {
  color: #111111;
  border-bottom: 1px solid transparent; }
.kt-portlet.kt-portlet--solid-warning .kt-portlet__head .kt-portlet__head-title {
  color: #111111; }
.kt-portlet.kt-portlet--solid-warning .kt-portlet__head .kt-portlet__head-title small {
  color: #020202; }
.kt-portlet.kt-portlet--solid-warning .kt-portlet__head .kt-portlet__head-icon i {
  color: #020202; }
.kt-portlet.kt-portlet--solid-warning .kt-portlet__body {
  padding-top: 5px; }
.kt-portlet.kt-portlet--solid-warning .kt-portlet__wrapper,
.kt-portlet.kt-portlet--solid-warning .kt-portlet__body {
  color: #111111; }
.kt-portlet.kt-portlet--solid-warning .kt-portlet__foot {
  color: #111111;
  border-top: 1px solid transparent;
  background: #ffae03; }
.kt-portlet.kt-portlet--tabs-border-3x-warning .kt-portlet__head {
  border-bottom: 3px solid rgba(255, 184, 34, 0.1); }
.kt-portlet.kt-portlet--border-bottom-warning {
  border-bottom: 3px solid rgba(255, 184, 34, 0.2); }
.kt-portlet.kt-portlet--solid-danger {
  background: #ab8f46; }
.kt-portlet.kt-portlet--solid-danger .kt-portlet__head {
  color: #ffffff;
  border-bottom: 1px solid transparent; }
.kt-portlet.kt-portlet--solid-danger .kt-portlet__head .kt-portlet__head-title {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-danger .kt-portlet__head .kt-portlet__head-title small {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-danger .kt-portlet__head .kt-portlet__head-icon i {
  color: #f0f0f0; }
.kt-portlet.kt-portlet--solid-danger .kt-portlet__body {
  padding-top: 5px; }
.kt-portlet.kt-portlet--solid-danger .kt-portlet__wrapper,
.kt-portlet.kt-portlet--solid-danger .kt-portlet__body {
  color: #ffffff; }
.kt-portlet.kt-portlet--solid-danger .kt-portlet__foot {
  color: #ffffff;
  border-top: 1px solid transparent;
  background: #fd1b66; }
.kt-portlet.kt-portlet--tabs-border-3x-danger .kt-portlet__head {
  border-bottom: 3px solid rgba(253, 57, 122, 0.1); }
.kt-portlet.kt-portlet--border-bottom-danger {
  border-bottom: 3px solid rgba(253, 57, 122, 0.2); }
.kt-portlet.kt-portlet--sortable .kt-portlet__head {
  cursor: move; }
.kt-portlet.kt-portlet--sortable-empty {
  visibility: hidden;
  height: 45px;
  min-height: 125px; }
.kt-portlet.ui-sortable-helper {
  border: 1px dashed #ebedf2; }
.kt-portlet .kt-portlet__head {
  transition: height 0.3s; }
.kt-portlet.kt-portlet--sticky .kt-portlet__head {
  transition: height 0.3s;
  position: fixed;
  box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.1);
  z-index: 101;
  background: #fff; }
.kt-portlet.kt-portlet--skin-solid .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title {
  color: #fff; }
.kt-portlet.kt-portlet--skin-solid .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-title small {
  color: rgba(255, 255, 255, 0.8); }
.kt-portlet.kt-portlet--skin-solid .kt-portlet__head .kt-portlet__head-label .kt-portlet__head-icon {
  color: rgba(255, 255, 255, 0.8); }
.kt-portlet.kt-portlet--skin-solid .kt-portlet__head:not(.kt-portlet__head--noborder) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
.kt-portlet.kt-portlet--skin-solid .kt-portlet__body {
  color: #fff; }
.kt-portlet .kt-portlet__space-x {
  padding-left: 25px !important;
  padding-right: 25px !important; }
.kt-portlet .kt-portlet__space-y {
  padding-top: 25px !important;
  padding-bottom: 25px !important; }
.kt-portlet.kt-portlet--collapsed > .kt-form,
.kt-portlet.kt-portlet--collapsed > .kt-portlet__body {
  display: none; }
.kt-portlet.kt-portlet--collapsed .kt-portlet__head .kt-portlet__head-toolbar .la-angle-down:before, .kt-portlet.kt-portlet--collapse .kt-portlet__head .kt-portlet__head-toolbar .la-angle-down:before {
  content: "\f113"; }
.kt-portlet.kt-portlet--collapsed .kt-portlet__head .kt-portlet__head-toolbar .la-plus:before, .kt-portlet.kt-portlet--collapse .kt-portlet__head .kt-portlet__head-toolbar .la-plus:before {
  content: "\f28e"; }

.tooltip-portlet {
  opacity: 1; }

/** INVOICES OVERVIEW **/
.kt-invoice-2 {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.kt-invoice-2 .kt-invoice__container {
  width: 100%;
  margin: 0;
  padding: 0 30px;
}

.kt-invoice-2 .kt-invoice__head {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px 0;
}

.kt-invoice-2 .kt-invoice__head .kt-invoice__container {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.kt-invoice-2 .kt-invoice__head .kt-invoice__brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.kt-invoice-2 .kt-invoice__head .kt-invoice__brand .kt-invoice__title {
  font-weight: 700;
  font-size: 2.7rem;
  margin-right: 10px;
  margin-top: 5px;
  color: #595d6e;
  vertical-align: top;
}

.kt-invoice-2 .kt-invoice__head .kt-invoice__brand .kt-invoice__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 5px;
  text-align: right;
  align-items: flex-end;
}

.kt-invoice-2 .kt-invoice__head .kt-invoice__brand .kt-invoice__logo img {
  text-align: right;
  max-width: 90px;
}

.kt-invoice-2 .kt-invoice__head .kt-invoice__brand .kt-invoice__logo .kt-invoice__desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: right;
  font-weight: 400;
  padding: 1rem 0 1rem 0;
  color: #74788d;
}

.kt-invoice-2 .kt-invoice__head .kt-invoice__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 50px;
  width: 100%;
  border-top: 1px solid #ebedf2;
}

.kt-invoice-2 .kt-invoice__head .kt-invoice__items .kt-invoice__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #595d6e;
  margin-right: 10px;
  margin-top: 20px;
}

.kt-invoice-2 .kt-invoice__head .kt-invoice__items .kt-invoice__item:last-child {
  margin-right: 0;
}

.kt-invoice-2 .kt-invoice__subtitle {
  font-weight: 500;
  padding-bottom: 0;
}

ul.print_invoice_info{
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  color: #74788d;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
ul.print_invoice_info li{
  flex: 1 0 50%;
}

.kt-invoice-2 .kt-invoice__head .kt-invoice__items .kt-invoice__item .kt-invoice__text {
  font-weight: 400;
  color: #74788d;
}

.kt-invoice-2 .kt-invoice__body {
  padding: 2rem 0;
  background-color: #f7f8fa;
}

.kt-invoice-2 .kt-invoice__body table {
  background-color: transparent;
}

.kt-invoice-2 .kt-invoice__body table thead tr th {
  background-color: transparent;
  padding: 1rem 0 0.5rem 0;
  color: #74788d;
  border-top: 0;
  border-bottom: 1px solid #ebedf2;
}

.kt-invoice-2 .kt-invoice__body table thead tr th:nth-child(1),
.kt-invoice-2 .kt-invoice__body table tbody tr td:nth-child(1),
.kt-invoice-2 .kt-invoice__body table thead tr th:nth-child(2),
.kt-invoice-2 .kt-invoice__body table tbody tr td:nth-child(2){text-align: left;}

.kt-invoice-2 .kt-invoice__body table thead tr th,
.kt-invoice-2 .kt-invoice__body table tbody tr td{
  text-align: right;
}

.kt-invoice-2 .kt-invoice__body table tbody tr td {
  background-color: transparent;
  padding: 1rem 0 1rem 0;
  border-top: none;
  font-weight: 700;
  font-size: 1rem;
  color: #595d6e;
}


.kt-invoice-2 .kt-invoice__body table tbody tr:first-child td {
  padding-top: 1.8rem;
}

.kt-invoice-2 .kt-invoice__footer {
  padding: 3rem 0;
}

.kt-invoice-2 .kt-invoice__footer .kt-invoice__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.kt-invoice-2 .kt-invoice__footer .table {
  background-color: transparent;
  padding: 0;
}

.kt-invoice-2 .kt-invoice__footer .table th {
  font-size: 1.1rem;
  text-transform: capitalize;
  font-weight: 500;
  color: #74788d;
  border-top: 0;
  border-bottom: 1px solid #ebedf2;
  padding: 10px 10px 10px 0;
  background-color: transparent;
}

.kt-invoice-2 .kt-invoice__footer .table th:last-child {
  padding-right: 0;
}

.kt-invoice-2 .kt-invoice__footer .table td {
  font-size: 1.1rem;
  text-transform: capitalize;
  background-color: transparent;
  font-weight: 500;
  color: #595d6e;
  padding: 10px 10px 10px 0;
}

.kt-invoice-2 .kt-invoice__footer .table td:last-child {
  padding-right: 0;
}

.kt-invoice-2 .kt-invoice__actions {
  padding: 2rem 0;
}

.kt-invoice-2 .kt-invoice__actions .kt-invoice__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}


@media (max-width: 768px) {
  .kt-invoice-2 .kt-invoice__container {
    width: 100%;
    margin: 0;
    padding: 0 20px;
  }

  .kt-invoice-2 .kt-invoice__head {
    padding: 20px 0;
  }

  .kt-invoice-2 .kt-invoice__head .kt-invoice__brand {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .kt-invoice-2 .kt-invoice__head .kt-invoice__brand .kt-invoice__title {
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .kt-invoice-2 .kt-invoice__head .kt-invoice__brand .kt-invoice__logo {
    text-align: left;
  }

  .kt-invoice-2 .kt-invoice__head .kt-invoice__brand .kt-invoice__logo img {
    text-align: left;
  }

  .kt-invoice-2 .kt-invoice__head .kt-invoice__brand .kt-invoice__logo .kt-invoice__desc {
    text-align: left;
  }

  .kt-invoice-2 .kt-invoice__head .kt-invoice__items {
    margin-top: 20px;
  }

  .kt-invoice-2 .kt-invoice__body {
    padding: 2rem 0;
  }

  .kt-invoice-2 .kt-invoice__footer {
    padding: 2rem 0;
  }
}

@media print {
  .kt-header,
  .kt-header-mobile,
  .kt-aside,
  .kt-footer,
  .kt-subheader,
  .kt-scrolltop,
  .kt-quick-panel,
  .kt-demo-panel,
  .kt-sticky-toolbar {
    display: none !important;
  }

  body,
  .kt-wrapper,
  .kt-body,
  .kt-content {
    background: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .kt-invoice-2 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .kt-invoice-2 .kt-invoice__head {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .kt-invoice-2 .kt-invoice__head .kt-invoice__container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .kt-invoice-2 .kt-invoice__actions {
    display: none !important;
  }

  .kt-invoice-2 .kt-invoice__footer {
    background-color: transparent !important;
  }

  .kt-invoice-2 .kt-invoice__container {
    width: 100%;
    padding: 0 10px;
  }
}
#loading-bg {
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
}

</style>
