<template>
  <div ref="signaturePadCanvas" class="signature-pad">
    <!-- This canvas will hold the signature pad -->
    <canvas :id="id" style="touch-action: none;"></canvas>
  </div>
</template>

<script>
import SignaturePad from 'signature_pad';

export default {
  name: "SignaturePad",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      signaturePad: null
    };
  },
  mounted() {
    const canvas = document.getElementById(this.id);
    this.signaturePad = new SignaturePad(canvas);
    // this.initializeSignaturePad();
  },
  methods: {
    initializeSignaturePad() {
      const canvas = document.getElementById(this.id);
      // Adjust for high DPI displays
      this.adjustCanvasForHighDPI(canvas);
      this.signaturePad = new SignaturePad(canvas);
    },
    adjustCanvasForHighDPI(canvas) {
      let ratio = Math.max(window.devicePixelRatio || 1, 1);
      // This part adjusts the canvas size
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);
      // This ensures that the drawn signature is scaled correctly
      this.signaturePad = new SignaturePad(canvas, { minWidth: 1, maxWidth: 2, penColor: "rgb(0, 0, 0)" });
    },
    // Method to clear the signature pad
    clear() {
      this.signaturePad.clear();
    },
    // Method to retrieve the signature data
    saveSignature() {
      if (!this.signaturePad.isEmpty()) {
        return this.signaturePad.toDataURL();
      }
      return '';
    }
  }
};
</script>

<style scoped>
.signature-pad {
  border: 1px solid #000;
  max-width: 302px;
}

.signature-pad canvas {
  width: 100%
}
</style>
